import axios from 'axios';
import store from '@/store';

// const API_URL = 'http://192.168.1.10:8002/api/';
const API_URL = 'https://admin.ecosethmining.net/api/'

// const API_URL = 'http://localhost:8000/api/';


axios.interceptors.request.use(
  function(request) {
    return request
  }, 
  function(error) {
    return Promise.reject(error)
  }
);

axios.interceptors.response.use(
  function(response) {
    return response
  }, 
  function (error) {
    store.state.index.loading = 0
    if (error.code == "ERR_NETWORK") {
      store.dispatch('showError',"Network Error")
    }else if(error.response.status == 500) {
      store.dispatch('showError',"Internal Server Error")
    } else if (error.response.status == 401) {
      // localStorage.removeItem('user');
      // window.location.reload()
      // router.push({ name: "login" })
    }

    return Promise.reject(error)
  }
);

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login', {
        phone: user.phone,
        password: user.password,
        name:user.name
      }).then(response => {
         let data = {
              ...response.data.data,
              ...response.data.token,
         };
        
            localStorage.setItem('user', JSON.stringify(data));
        return response.data;
      });
  }

  logout() {
    // localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'register', user, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(response => {
      console.log('register')
      if (response.data.token) {
          let data = {
              ...response.data.data,
              token:response.data.token,
         };
        
        console.log(data)
          localStorage.setItem('user', JSON.stringify(data));
        }
        return response.data;
      });
  }
}

export default new AuthService();